import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  getAffiliateIdFromQueryString,
  getAffiliateCodeFromQueryString,
  getLimitedTimeCertPromotionActiveFromQueryString,
  getDeeplinkedPageSectionKeyFromQueryString,
  getDeeplinkedTestimonialCategoryFromQueryString,
  getDeeplinkedCheckoutPlanFromQueryString,
  MinDesktopWidthPixels,
  isIOSDevice,
  isAndroidDevice,
  getIsMetaInAppBrowser,
  NeuroFitCourseCheckoutPage,
  NeuroFitCertificationCheckoutPage,
  recordLinkClickAndRedirect,
  CertificationThreePaymentCheckoutLink,
  NeuroFitCertificationConfirmationCallLink,
  NeuroFitInstagramLink,
  getNextEnrollmentCloseUnixTimestamp,
  getDiscountCodeExpirationMetadata,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
  subdomainUrls,
  currentTime,
  getCurrentYear,
  getAdvertisedYear,
  loadScriptAsync,
  MouseFlowScript,
  delay,
} from '../../utils'

import CertificationPaymentResultModal from '../modals/CertificationPaymentResultModal'
import SatisfactionGuaranteeModal from '../modals/SatisfactionGuaranteeModal'
import CertificationVslModal from '../modals/CertificationVslModal'
import QrCodeDownloadModal from '../modals/QrCodeDownloadModal'
import MetaInAppDownloadModal from '../modals/MetaInAppDownloadModal'

import {scroller, Element} from 'react-scroll'
import StarRatings from 'react-star-ratings';

import { Haptics, ImpactStyle } from '@capacitor/haptics';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import InlineVideoContainer from './InlineVideoContainer'
import LineGraph from './LineGraph'
import UniversitySourcesSection from './UniversitySourcesSection'
import FaqSection from './FaqSection'
import CertificationPageProductTile from './CertificationPageProductTile'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import DownloadButtonsSection from './DownloadButtonsSection'
import InlineTrainerTestimonial from './InlineTrainerTestimonial'

import SendRequestButton from './SendRequestButton'

import Countdown from 'react-countdown';

import {
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
} from '../../constants/requestStates'

import {
  GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON,
  GA_LABEL_STARTED_CERTIFICATION_LOREN_VIDEO,
  GA_LABEL_COMPLETED_CERTIFICATION_LOREN_VIDEO,
  GA_LABEL_CLICKED_REDIRECT_TO_COURSE_CHECKOUT_PAGE,
  GA_LABEL_CLICKED_REDIRECT_TO_CERTIFICATION_CHECKOUT_PAGE,
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_VSL_BUTTON,
  GA_LABEL_CLICKED_BOOK_CERTIFICATION_CALL_LINK,
  GA_LABEL_OPENED_CERTIFICATION_DEEPLINK,
  GA_LABEL_CLICKED_CERTIFICATION_INDUSTRY_CATEGORY_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_CONTACT_EMAIL_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_CONTACT_INSTAGRAM_BUTTON,
} from '../../constants/gaEventLabels'

import {
  ExternalLinkSvg,
  CopySvg,
  SendArrowSvg,
  VerifiedSvg,
  InfoSvg,
  OvalSpinnerSvg,
  ClockSvg,
  AppleSvg,
  AndroidSvg,
  EmailSvg,
  InstagramSvg,
} from '../../constants/svgs'

import { ScrollPercentage } from 'react-scroll-percentage'

import loadable from '@loadable/component'

const CertificationStripeCheckoutForm = loadable(() => import('./CertificationStripeCheckoutForm'))
const TrainerTestimonials = loadable(() => import('./TrainerTestimonials'))
const TrainerFAQs = loadable(() => import('./TrainerFAQs'))



const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const HealthProfessionalTestimonials = [
  {
    display_name: "Life Coaches",
    category_key: "life_coaches",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Michelle"}
        occupation={"Master Life Coach"}
        countryFlag={"🇺🇸"}
        profileImageSrc={"/images/trainers/michelle.jpeg"}
        testimonialText={"The NEUROFIT platform is truly revolutionary. Having access to a simple, science-backed way for myself and my clients to quickly balance their nervous systems is so powerful. This will have such a positive impact on education, industry, business, relationships, and families, and I'm so grateful to be at the forefront."}
      />
    )
  },
  {
    display_name: "Doctors",
    category_key: "doctors",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Olivia"}
        occupation={"Physician + TedX Speaker"}
        countryFlag={"🇦🇺"}
        profileImageSrc={"/images/trainers/olivia.jpeg"}
        testimonialText={"NEUROFIT has had a beneficial impact on my personal and professional life. The daily balance exercises help me recalibrate and reset my nervous system, making me feel calmer and more confident. It has improved my performance as a physician and high-performance leadership coach."}
      />
    )
  },
  {
    display_name: "Healers",
    category_key: "healers",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Stacey"}
        occupation={"Reiki + Energy Healer"}
        countryFlag={"🇦🇺"}
        testimonialText={"Since NEUROFIT I am able to regulate my anxiety and feel more grounded, clear minded and less reactive. I am able to do way more in my business instead of being injured, tired and burnout all the time, I can actually get myself motivated to get my tasks done and take on more new clients which is bringing in way more income for myself for the very first time!"}
      />
    )
  },
  {
    display_name: "Therapists",
    category_key: "therapists",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Kerry"}
        occupation={"Licensed Therapist"}
        profileImageSrc={"/images/trainers/kerry.jpeg"}
        countryFlag={"🇺🇸"}
        testimonialText={"With NEUROFIT I was able to develop my first ever coaching program! I signed 5 dream clients and made $10k in under two months. As a therapist who specializes in trauma and anxiety, NEUROFIT was the missing piece for my clients to truly understand themselves and learn tools that would give them permanent results. It taught me a new perspective on ROOT CAUSE instead of bandaid treatments. NEUROFIT has not only changed my life professionally, but as someone who has dealt with complex trauma, burnout and debilitating chronic pain, this was the first year of my life that I can truly say I'm anxiety free and I feel safe and energized in my own body. NEUROFIT has changed my life in ways that I'll be forever grateful."}
      />
    )
  },
  {
    display_name: "Performance Coaches",
    category_key: "performance_coaches",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Jon"}
        occupation={"Performance Consultant"}
        profileImageSrc={"/images/trainers/jon.jpeg"}
        countryFlag={"🇺🇸"}
        testimonialText={"This work has the potential to transform lives... and I don't use that phrase lightly. Seeing progress within the app keeps you motivated and you will notice an impact within a matter of days."}
      />
    )
  },
  {
    display_name: "Fitness Trainers",
    category_key: "fitness_trainers",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Ashley"}
        occupation={"Personal Trainer"}
        countryFlag={"🇺🇸"}
        profileImageSrc={"/images/trainers/ashley.jpeg"}
        testimonialText={"The NEUROFIT certification has been profoundly transformative for me. Of all the certifications and continuing education I’ve done, this one was not only the most impactful, but also delivered in the simplest way."}
      />
    )
  },
  {
    display_name: "Pilates Instructors",
    category_key: "pilates_instructors",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Jenny"}
        occupation={"Pilates Studio Owner"}
        countryFlag={"🇺🇸"}
        testimonialText={"I've been so impressed by how concise and direct this training has been while being incredibly powerful. Making the simple shift of incorporating daily play has created a significant shift in my body and life! I have real tools to respond to life and balance my nervous system."}
      />
    )
  },
  {
    display_name: "Health Coaches",
    category_key: "health_coaches",
    testimonial: (
      <InlineTrainerTestimonial
       name={"Kristine"}
       occupation={"Holistic Health Coach"}
       countryFlag={"🇦🇺"}
       profileImageSrc={"/images/trainers/kristine.jpeg"}
       testimonialText={"Being informed on how to facilitate somatic transformations gives me so much confidence as a coach. I'm so honored and excited to be an expert in this space. This is going to change the game in a number of industries because nervous system regulation is key."}
      />
    )
  },
  {
    display_name: "Physical Therapists",
    category_key: "physical_therapists",
    testimonial: (
      <InlineTrainerTestimonial
        name={"Sarah"}
        occupation={"DPT"}
        countryFlag={"🇺🇸"}
        testimonialText={"I am a DPT focusing on holistic care and CNS regulation in my practice. The exercises I've learned in training and in the app are so accessible and simple and easy to incorporate into everyday life. I especially love the ENS massage and tree shaking and sacred rage! I have known I want to focus on sustainable CNS regulation for a while, but the NF training has finally given me a fantastic guide on how to make that a reality and focus my marketing and particular niche. I and many of my clients are athletes and/or have struggled with anxiety/trauma/self-esteem for most of our lives, and initial implementation of this training has already helped break down barriers so much!!"}
      />
    )
  }
]

const LimitedPromoCode = "JAN50"
const LimitedPromoCodeExpirationTimestampUnix = 1706601600

const CourseOutlineBulletPoint = ({text}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "start", marginTop: "0.4em", padding: "0px 2vh"}}>
    <div style={{display: "inline-block", fontSize: "30px", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Medium", marginInlineEnd: "0.2em"}}>
      {"•"}
    </div>
    <div style={{fontFamily: "Futura Light", fontSize: "min(5vw, 20px)", lineHeight: "min(6vw, 26px)", textAlign: "start"}}>
      {text}
    </div>
  </div>
)

const CourseOutlineBulletPoints = ({textList}) => (
  <div>
    {textList.map(t => (
      <CourseOutlineBulletPoint key={t} text={t} />
    ))}
  </div>
);

const CourseOutlineItems = [
  {
    key: "module_1",
    question: "Module 1: Setting Yourself Up for Success",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The 3-Part Transformation Framework that creates accelerated results",
          "The #1 avoidable mistake that most nervous system experts make",
          "The 4 Truths that will uplevel your standards and dramatically improve your results"
        ]}
      />
    )
  },
  {
    key: "module_2",
    question: "Module 2: Introduction to the NEUROFIT Method",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The fundamentals of the NEUROFIT Method (and why it's so effective)",
          "How to practically apply Polyvagal Theory with clients",
          "4 Key Factors that secretly influence the nervous system",
          "Using Bottom-Up Processing to your advantage",
          "The 6 nervous system states, and how to quickly identify them",
          "How to measure nervous system balance in less than 2 minutes"
        ]}
      />
    )
  },
  {
    key: "module_3",
    question: "Module 3: Taking a Client from Dysregulated to Balanced in Minutes (A Step-by-Step Process)",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "Understanding nervous system energy types to get faster client breakthroughs",
          "The best-performing somatic exercises when working with anxious, overwhelmed, or shut-down clients"
        ]}
      />
    )
  },
  {
    key: "module_4",
    question: "Module 4: Advanced Techniques for Personal & Client Breakthroughs",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "What to do (on a nervous system level) when a client experiences resistance to change",
          "Facilitating client safety during sessions via the social nervous system",
          "Advanced somatic and balancing techniques for facilitating powerful client breakthroughs",
          "The highest-performing accountability habits that are proven to accelerate client results"
        ]}
      />
    )
  },
  {
    key: "module_5",
    question: "Module 5: Facilitating Powerful Transformations with Nervous System Fitness™",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "A step-by-step process for hosting transformational workshops that your clients will rave about",
          "A walkthrough guide for facilitating life-changing 1:1 client sessions"
        ]}
      />
    )
  },
  {
    key: "module_6",
    question: "Module 6: Done-For-You Business Suite (Marketing & Distribution)",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "“Business Audit” Workbook: quickly identify how your nervous system is affecting your business (and what to do about it)",
          "Step-by-Step of how to a build a Nervous System + Somatics offer into your current business",
          "How to host successful nervous system workshops or 1:1 somatics sessions that your clients rave about",
          "Step-by-step process of building a successful funnel for your new offer",
          "How to grow your authority and build your brand with social media",
          "Your 6-week nervous system fitness program",
          "Training Webinar Template (PDF download)",
          "PDF copies of high-converting Optin Page + Sales Page",
          "10+ email templates you can plug into your business",
          "20+ Instagram templates that you can use immediately",
          "30+ content headlines to spark your inspiration",
          "10+ of the best automation tools to streamline your business",
          "5 Pre-Vetted NEUROFIT Coach offerings you can provide to clients immediately"
        ]}
      />
    )
  }
]

let defaultCourseItemsShowDetail = {}
CourseOutlineItems.map(key => defaultCourseItemsShowDetail[key] = false)

class TrainerCertificationDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isIOS: isIOSDevice(),
      isAndroid: isAndroidDevice(),
      isMetaInAppBrowser: getIsMetaInAppBrowser(),
      showCourseDetail: defaultCourseItemsShowDetail,
      hasEntered: false,
      showSettingsModal: false,
      learnMoreRef: React.createRef(),
      superiorResultsRef: React.createRef(),
      testimonialsRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      coachBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      certLimitedTimePromotionActive: getLimitedTimeCertPromotionActiveFromQueryString(),
      codeIsCopied: false,
      showPaymentResultModal: false,
      showSatisfactionGuaranteeModal: false,
      showCertificationVslModal: false,
      showQrCodeModal: false,
      showMetaInAppDownloadModal: false,
      paymentSuccess: false,
      showSpinner: false,
      selectedHealthProfessionalCategory: "life_coaches"
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  hapticLightOnNative = () => {
    if (this.props.isNative) {
      Haptics.impact({ style: ImpactStyle.Light })
    }
  }

  adjustBgPositionById = (id, scrollTop, innerHeight, xvalue="center", bgImage=undefined, blur=undefined, backgroundSize=undefined) => {
    let target = document.getElementById(id)

    // let factor = this.props.isIOSDevice ? 0.0 : 0.0 //0.2
    // let yvalue = (target.offsetTop - scrollTop) * factor
    // let yvalueSign = `${(yvalue > 0 ? "-" : "")}`

    if (!!(bgImage) && !!(target)) {
      target.style.backgroundImage = bgImage

      if ((blur !== undefined)) {
        target.style.filter = blur
        target.style.WebkitFilter = blur
      }
      if ((backgroundSize !== undefined)) {
        target.style.backgroundSize = backgroundSize
      }
    }

    // target.style.backgroundPosition = `${xvalue} ${yvalue}px`
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const hasScrolled = true

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, heroOverlayColor, hasScrolled, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", coachBackdropFilter: "none", topPageScrollProgress: 0, dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    const {hasEntered} = this.state
    if (hasEntered) {
      return
    }
    
    this.setState({hasEntered: true})

    const {innerHeight, isNative, deeplinkedPageSectionKey, deeplinkedTestimonialCategoryKey} = this.props
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    if (!!deeplinkedPageSectionKey) {
      const checkoutPlanKey = getDeeplinkedCheckoutPlanFromQueryString(window.location.href)
      const testimonialCategory = getDeeplinkedTestimonialCategoryFromQueryString(window.location.href)
      recordFunnelEventResultGA(
        GA_LABEL_OPENED_CERTIFICATION_DEEPLINK,
        {
          deeplink_key: deeplinkedPageSectionKey,
          checkout_plan: checkoutPlanKey,
          testimonial_category: testimonialCategory
        }
      )
    }

    if (deeplinkedPageSectionKey === "testimonials" || deeplinkedPageSectionKey === "checkout") {
      this.setState({showSpinner: true})
      if (document.readyState === 'complete') {
        await this.completeDeeplinkPageLoad()
      }
      if (!!window.addEventListener) {
        window.addEventListener("load", async () => {
          await this.completeDeeplinkPageLoad()
        })
      } else {
        window.attachEvent("load", async () => {
          await this.completeDeeplinkPageLoad()
        })
      }
    } else {
      if (!isNative) {
        loadScriptAsync(MouseFlowScript)
      }
    }
  }

  async completeDeeplinkPageLoad() {
    const {innerHeight, isNative, deeplinkedPageSectionKey, deeplinkedTestimonialCategoryKey} = this.props
    await delay(500)

    while (this.props.certificationCodeDetailsRequest.state === REQUEST_FETCHING) {
      await delay(200)
    }

    this.setState({showSpinner: false})

    if (deeplinkedPageSectionKey !== "checkout") {
      scroller.scrollTo(deeplinkedPageSectionKey, {
        container: this.scrollingContainerRef,
      });
    }

    if (!isNative) {
      loadScriptAsync(MouseFlowScript)
    }
  }


  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      onClickNativeVslLink,
      deeplinkedAffiliateCode,
      deeplinkedCheckoutPlanKey,
      deeplinkedTestimonialCategoryKey,
      certificationCodeDetailsRequest
    } = this.props


    const {show, showPaymentResultModal, showQrCodeModal, showMetaInAppDownloadModal, showSatisfactionGuaranteeModal, showCertificationVslModal, showSpinner, paymentSuccess, isIOS, isAndroid, isMetaInAppBrowser, selectedHealthProfessionalCategory, certLimitedTimePromotionActive, showCourseDetail, learnMoreRef, hasScrolled, showSettingsModal, scrollingContainerRef, superiorResultsRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, coachBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, codeIsCopied} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels
    const deviceType = isMobile ? "mobile" : "desktop"
    const bundleDeviceRatio = 1 // isMobile ?  : 844 / 1500

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const enrollmentCloseDate = getNextEnrollmentCloseUnixTimestamp()

    const discountCodeExpirationMetadata = (certificationCodeDetailsRequest.state === REQUEST_SUCCESS) && getDiscountCodeExpirationMetadata(certificationCodeDetailsRequest.result.expires_at_unix)

    // const isPageLoadComplete = (certificationCodeDetailsRequest.state !== REQUEST_FETCHING) && isHeroImageLoaded

    const LimitedPromoCodeExpirationDate = getDiscountCodeExpirationMetadata(LimitedPromoCodeExpirationTimestampUnix)

    const currentMonthDisplayName = currentTime().format("MMMM")

    const currentYear = getCurrentYear()
    const advertisedYear = getAdvertisedYear()

    const selectedHealthProfessionalTestimonial = HealthProfessionalTestimonials.filter(t => selectedHealthProfessionalCategory === t.category_key)[0]

    const screenshotHeightPixels = Math.min(innerHeight * 0.55, 500)
    const deviceScreenshotAspectRatio = 2.037
    const screenshotWidthPixels = screenshotHeightPixels / deviceScreenshotAspectRatio

    const showBannerThresholdExpirationDays = 1.0

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <CSSTransition
          in={showSpinner}
          timeout={400}
          classNames="fade-in"
          unmountOnExit
        >
          <div disabled="1" style={{position: "fixed", top: 0, left: 0, bottom: 0, backdropFilter: "blur(15px)", WebkitBackdropFilter: "blur(15px)", right: 0, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(207, 210, 221, 0.3)", pointerEvents: "all", zIndex: 10000000}}>
            <OvalSpinnerSvg size={"min(8.5vw, 40px)"} color={"#FFFFFF"}  />
          </div>
        </CSSTransition>
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#FFFFFF"}}>
          <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px"}}>
            <div style={{color: "black", backgroundColor: "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div>
                  <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start", whitepace: "pre-wrap"}}>
                    <div>{isMobile ? "Become a Certified Nervous System + Somatics Coach" : "Become A Certified Somatics + Nervous System Coach"}</div>
                  </div>
                  <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                    <span>{`Master nervous system+somatics ${(advertisedYear === currentYear) ? "in" : "for"} ${advertisedYear} and attract new clients 10x faster with our`}</span>
                    <span style={{fontFamily: "Futura"}}>{` all-in-one certification + tech platform`}</span>
                    <span>{`.`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{color: "black", backgroundColor: "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "95%", maxWidth: 700}}>
                <div>
                  <div style={{width: "100%", marginTop: "4vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img
                      src={isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH}
                      fetchpriority="high"
                      onLoad={() => {
                        this.setState({isHeroImageLoaded: true})
                        initializePixelIfAvailable(!isNative/*initTikTok*/)
                      }}
                      style={{maxWidth: 600, width: "100%", height: "calc(min(95vw, 600px) / 1.905)", opacity: isHeroImageLoaded ? 1.0 : 0.0, transition: "opacity 150ms linear"}}
                      alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{color: "black", backgroundColor: "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 500}}>
                <div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "4vh", pointerEvents: "none"}}>
                    <img src={isMobile ? "/images/media-logos-4-mobile.webp?v=3" : "/images/media-logos-4-desktop.webp?v=3"} fetchpriority="high" style={{width: "100%", maxWidth: 700, height: "calc(min(85vw, 500px) / 8.577)", opacity: 0.6}} alt="NEUROFIT has been featured in SHAPE, Well and Good, and Body and Soul." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw"}} ref={elem => this.superiorResultsRef = elem}>
          {(certificationCodeDetailsRequest.state === REQUEST_SUCCESS && (discountCodeExpirationMetadata.days_since_expiration <= 1)) && (
            <div style={{padding: "5vh 0px", backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
              <div style={{display: "inline-block", color: "#FFFFFF", width: "85%", maxWidth: 700}}>
                <div id="discount-link-label" style={{fontFamily: "Futura", fontSize: "min(6.5vw, 30px)"}}>
                  {`${certificationCodeDetailsRequest.result.first_name}, this unique discount link grants 50% off.`}
                </div>
                {(discountCodeExpirationMetadata.days_since_expiration > 0) ? (
                  <div style={{fontFamily: "Futura", marginTop: "2vh", fontSize: "min(6.5vw, 30px)"}}>
                    <div style={{display: "inline-block"}}>{`Join now to redeem your discount - it will be deleted from our systems within the next few hours.`}</div>
                  </div>
                ) : (
                  <div>
                    <div style={{fontFamily: "Futura", marginTop: "2vh", fontSize: "min(6.5vw, 30px)"}}>
                      <div style={{display: "inline-block"}}>{`Your discount will apply automatically at checkout, and it expires in:`}</div>
                    </div>
                    <div style={{fontFamily: "Futura Medium", marginTop: "2vh"}}>
                      <Countdown
                        date={discountCodeExpirationMetadata.expiration_date}
                        renderer={({days, hours, minutes, seconds, completed }) => {
                          // Render a countdown
                          return (
                            <span style={{fontFamily: "Futura Medium", fontSize: "min(8.5vw, 40px)", textAlign: "center"}}>{`${days}D ${hours}H ${minutes}M ${seconds}S`}</span>
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {(certLimitedTimePromotionActive) && (
            <div style={{padding: "5vh 0px", backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
              <div style={{display: "inline-block", color: "#FFFFFF", width: "85%", maxWidth: 700}}>
                <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)"}}>
                  {`LIMITED-TIME OFFER`}
                </div>
                <div style={{fontFamily: "Futura", marginTop: "2vh", fontSize: "min(6.5vw, 30px)"}}>
                  {`Get 50% off the Certification.`}
                </div>
{/*                <button
                  onClick={() => {
                    this.setState({codeIsCopied: true})
                    navigator.clipboard.writeText(LimitedPromoCode)
                    this.hapticLightOnNative()
                    setTimeout(() => {
                      this.setState({codeIsCopied: false})
                    }, 1000)
                  }}
                  style={{border: "none", backgroundColor: "#FFFFFF", margin: "2vh 0px", padding: "12px 18px", display: "flex", alignItems: "center", justifyContent: "center"}}
                >
                  <span style={{fontFamily: "Futura Medium", marginInlineEnd: "0.2em", fontSize: "min(8vw, 40px)", color: codeIsCopied ? "#555" : "#000000"}}>
                    {codeIsCopied ? "COPIED CODE" : LimitedPromoCode}
                  </span>
                  <CopySvg
                    color={codeIsCopied ? "#555" : "#000000"}
                    size={"min(8vw, 40px)"}
                  />
                </button>*/}
                {(LimitedPromoCodeExpirationDate.days_since_expiration > 0) ? (
                  <div style={{fontFamily: "Futura", marginTop: "2vh", fontSize: "min(6.5vw, 30px)"}}>
                    <div style={{display: "inline-block"}}>{`Join now to redeem - this discount will be deleted from our systems within the next few hours.`}</div>
                  </div>
                ) : (
                  <div>
                    <div style={{fontFamily: "Futura", marginTop: "2vh", fontSize: "min(6.5vw, 30px)"}}>
                      <div style={{display: "inline-block"}}>{`This discount expires in:`}</div>
                    </div>
                    <div style={{fontFamily: "Futura Medium", marginTop: "2vh"}}>
                      <Countdown
                        date={LimitedPromoCodeExpirationDate.expiration_date}
                        renderer={({days, hours, minutes, seconds, completed }) => {
                          // Render a countdown
                          return (
                            <span style={{fontFamily: "Futura Medium", fontSize: "min(8.5vw, 40px)", textAlign: "center"}}>{`${days}D ${hours}H ${minutes}M ${seconds}S`}</span>
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px calc(60px + 5vh) 0px", textAlign: "start", borderTop: "2px #000000 solid", width: "100vw", backgroundColor: "#EDEEF2"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8.5vw, 40px)", textAlign: "start"}}>
                {`Loved By Leading Wellness Pro's. Built For ${advertisedYear}.`}
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                <span>{"Access our "}</span>
                <span style={{fontFamily: "Futura"}}>{`neuroscience-based `}</span>
                <span>{` certification + coaching tech to:`}</span>
              </div>
              <div style={{display: "flex", margin: "2vh 0px", width: "100%", justifyContent: "center"}}>
                <div style={{maxWidth: "min(100%, 600px)"}}>
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button"}
                    text={"UNLOCK CONFIDENCE"}
                  />
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(4.5vw, 18px)", marginTop: "0.5vh", marginBottom: "1vh"}}>
                    {"with our transformative step-by-step approach"}
                  </div>
                  <InlineTrainerTestimonial
                    name={"Julia"}
                    occupation={"Confidence Coach"}
                    profileImageSrc={"/images/trainers/julia.jpeg"}
                    countryFlag={"🇮🇩"}
                    testimonialText={"I've seen a DRAMATIC improvement in how I feel and I've booked new clients after doing NEUROFIT exercises. Absolutely love this work."}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button"}
                    text={"ATTRACT NEW CLIENTS"}
                  />
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(4.5vw, 18px)", marginTop: "0.5vh", marginBottom: "1vh"}}>
                    {"in a $1T+ preventive wellness market"}
                  </div>
                  <InlineTrainerTestimonial
                    name={"Kerry"}
                    occupation={"Licensed Therapist"}
                    profileImageSrc={"/images/trainers/kerry.jpeg"}
                    countryFlag={"🇺🇸"}
                    testimonialText={"I developed my first-ever coaching program! Signed 5 dream clients and made $10K in under two months."}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button"}
                    text={"MASTER NERVOUS SYSTEM"}
                  />
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(4.5vw, 18px)", marginTop: "0.5vh", marginBottom: "1vh"}}>
                    {"with our streamlined program + proven tech"}
                  </div>
                  <InlineTrainerTestimonial
                    name={"Olivia"}
                    occupation={"Physician + TedX Speaker"}
                    countryFlag={"🇦🇺"}
                    profileImageSrc={"/images/trainers/olivia.jpeg"}
                    testimonialText={"NEUROFIT has helped me recalibrate my nervous system, making me feel calmer, more confident, and improving my performance."}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button"}
                    text={"GET EXCEPTIONAL RESULTS"}
                  />
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(4.5vw, 18px)", marginTop: "0.5vh", marginBottom: "1vh"}}>
                    {"with our all-in-one coaching platform"}
                  </div>
                  <InlineTrainerTestimonial
                    name={"Michelle"}
                    occupation={"Master Life Coach"}
                    profileImageSrc={"/images/trainers/michelle.jpeg"}
                    countryFlag={"🇺🇸"}
                    testimonialText={"Just had a $13K launch from enrollments in my nervous system program, and am booked out 1:1 with a client waitlist!"}
                  />
                </div>
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Book", fontStyle: "italic", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                <span>{"We've spent "}</span>
                <span style={{fontFamily: "Futura"}}>{" 10,000+ hours "}</span>
                <span>{" refining this platform so you can master somatics, get unmatched results, and grow your business."}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
          <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/shape-groundbreaking-recovery-innovations-white.webp?v=3" style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)", height: "calc(min(calc(75% * 0.908), calc(400px * 0.908) * 152 / 501)"}} style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)"}} alt="NEUROFIT is a SHAPE Magazine Groundbreaking Recovery Innovation." />
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/well-and-good-laurel-white.webp?v=2" style={{width: "min(75%, 400px)", height: "calc(min(75%, 400px) * 120 / 501)"}} alt="NEUROFIT eased my stress in ways meditation never has -Well and Good" />
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
            <img src="/images/prevention-laurel-white.webp?v=2" style={{width: "75%", maxWidth: 400, height: "calc(min(75%, 400px) * 132 / 501)"}} alt="Balance your nervous system in 3 minutes or less. -Prevention" />
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/nasm-afaa-black-and-white.webp?v=2" style={{width: "min(80%, 500px)", height: "calc(min(80%, 500px) * 252 / 897)"}} alt="NEUROFIT is a NASM + AFAA Approved Provider." />
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8.5vw, 40px)", textAlign: "start"}}>
                {"What You'll Get"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "95%", maxWidth: 900}}>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "6vh auto"}}>
                <img fetchpriority="high" src={`/images/certification-assets/bundle-${deviceType}-black.webp`} style={{width: "min(100%, 900px)", height: `calc(min(100%, 900px) * ${bundleDeviceRatio})`}} alt="A 3-Week Nervous System Certification Course for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "5vh", textAlign: "start"}}>
                {"I. NERVOUS SYSTEM + SOMATICS COURSE"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $3,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                  <img fetchpriority="high" src={"/images/certification-course.webp"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 1123 / 1500)"}} alt="A 3-Week Nervous System Certification Course for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Master somatic techniques and nervous system regulation with our online certification course."}
              </div>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "8vh", textAlign: "start"}}>
                {"II. COACHING TECH"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $2,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                <img fetchpriority="low" src={isMobile ? "/images/certification-hero-dash-mobile.webp?v=4" : "/images/certification-hero-dash.webp?v=4"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 1152 / 1500)"}} alt="NEUROFIT Nervous System Coach Technology and Toolkit." />
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                {"COACH DASHBOARD"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Monitor and guide your clients with data-driven insights, collect testimonials with 1 click, and earn 20% affiliate commission."}
              </div>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                {"CLIENT LEAD TOOLS"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Get more client leads with our Discovery Quiz, then book them with AI Sales Assistant."}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "8vh", textAlign: "start"}}>
                {"III. DONE-FOR-YOU BUSINESS SUITE"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $2,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                <img fetchpriority="low" src={"/images/certification-business-suite.webp"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 902 / 1500)"}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Skip the guesswork + launch your next offer faster. Learn how to package, sell out, and run:"}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "start", padding: "1vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "35px", lineHeight: "min(9vw, 40px)", fontFamily: "Futura Medium", marginInlineEnd: "0.2em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "start"}}>
                  {"6-Week Group Programs"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "start", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "35px", lineHeight: "min(9vw, 40px)", fontFamily: "Futura Medium", marginInlineEnd: "0.2em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Book",  fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "start"}}>
                  {"Online Class Memberships"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "start", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "35px", lineHeight: "min(9vw, 40px)", fontFamily: "Futura Medium", marginInlineEnd: "0.2em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "start"}}>
                  {"1-On-1 Client Sessions"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "start", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "35px", lineHeight: "min(9vw, 40px)", fontFamily: "Futura Medium", marginInlineEnd: "0.2em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "start"}}>
                  {"Live Group Workshops"}
                </div>
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", marginTop: "8vh", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <InlineTrainerTestimonial
                name={"Laura"}
                occupation={"Registered Nurse"}
                countryFlag={"🇺🇸"}
                backgroundColor={"#EDEEF2"}
                testimonialText={"I've found this platform extremely informative and useful. The business strategy resources it provides are pivotal in applying nervous system fitness to your niche."}
              />
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Medium", fontSize: "min(7.5vw, 32px)", marginTop: "8vh", textAlign: "start"}}>
                {"WELCOME GIFT #1:"}
              </div>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "1vh", textAlign: "start"}}>
                {"PROFITABLE CHALLENGE FORMULA"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                <img fetchpriority="low" src={"/images/cert-profitable-funnel-challenge.webp"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 789 / 1500)"}} alt="The NEUROFIT Profitable Challenge Formula." />
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Ace your next online challenge with our proven 7-figure template for wellness professionals."}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Medium", fontSize: "min(7.5vw, 32px)", marginTop: "8vh", textAlign: "start"}}>
                {"WELCOME GIFT #2:"}
              </div>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "1vh", textAlign: "start"}}>
                {"SOMATIC FACILITATOR GUIDE"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $500 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                <img fetchpriority="low" src={"/images/nf-facilitator-manual-desktop.webp"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 1254 / 1500)"}} alt="The NEUROFIT Profitable Challenge Formula." />
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Master facilitating group + 1:1 somatics sessions with this fully detailed training + guidebook."}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Medium", fontSize: "min(7.5vw, 32px)", marginTop: "8vh", textAlign: "start"}}>
                {"WELCOME GIFT #3:"}
              </div>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "1vh", textAlign: "start"}}>
                {"LIVE COACHING + COMMUNITY"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "start"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                <img fetchpriority="low" src={"/images/certification-trainer-community.webp"} style={{width: "min(100%, 500px)", height: "calc(min(100%, 500px) * 792 / 1500)"}} alt="NEUROFIT Certified Nervous System Trainer Community." />
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                {"LIVE COACHING CALLS"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Hands-on support covering somatic breakthroughs, business, and more. Includes three free monthly community calls."}
              </div>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                {"CERTIFIED COACH COMMUNITY"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"Connect with other coaches, get your business questions answered, and trade somatics sessions to polish your skills."}
              </div>
            </div>
          </div>
        </div>
{/*        <div style={{padding: "calc(60px + 5vh) 0px 5vh 0px", textAlign: "start", borderTop: "2px #000000 solid", width: "100vw", backgroundColor: "#EDEEF2"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(8.5vw, 40px)", textAlign: "start"}}>  
                {"MEET THE FOUNDERS"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"NEUROFIT is led by husband-and-wife Co-CEOs Loren and Andrew."}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "3vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                {"They draw on 20+ years of experience in applied somatics and wellness tech to co-create all NEUROFIT products in-house."}
              </div>
              <InlineTrainerTestimonial
                name={"Loren"}
                occupation={"Curriculum + Growth"}
                certifiedText={"7+ Figure Somatics Coach"}
                countryFlag={"🇦🇺"}
                profileImageSrc={"/images/trainers/loren.webp"}
                testimonialText={"After working with 3,000+ clients and 500+ wellness professionals, I've learned first-hand the value of seamless learning experiences."}
              />
              <InlineTrainerTestimonial
                name={"Andrew"}
                occupation={"Product + Tech"}
                certifiedText={"Venture-backed founder"}
                countryFlag={"🇺🇸"}
                profileImageSrc={"/images/trainers/andrew.webp"}
                testimonialText={"After 10 years of designing and building wellness tech, one need became clear: streamlined products that get results fast."}
              />
            </div>
          </div>
        </div>*/}
        <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0vh", backgroundColor: "#000000"}}>
          <div style={{color: "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8.5vw, 40px)", textAlign: "start"}}>
                {`The Certification + Coaching Tech Built For ${advertisedYear}`}
              </div>
              <div style={{display: "flex", marginTop: "3vh", width: "100%", justifyContent: "center"}}>
                <div style={{maxWidth: "min(100%, 600px)"}}>
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"Online Certification Course"}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"World-Class Coaching Tech"}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"Client Lead-Gen Tools"}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"Done-For-You Business Suite"}
                  />

                  <div style={{marginTop: "6vh", marginBottom: "2vh", fontFamily: "Futura Medium", fontSize: "min(6vw, 26px)"}}>
                    {"WITH WELCOME GIFTS 🎁"}
                  </div>
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"7-Figure Challenge Formula"}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"Somatic Facilitator Guide"}
                  />
                  <MembershipFeatureDescription
                    fontSize={"min(6vw, 26px)"}
                    fontFamily={"Futura"}
                    checkMarginTop={"0.35em"}
                    checkboxBaseClassName={"cert-page-plan-button-white"}
                    textColor={"#FFFFFF"}
                    checkColorComplete={"#000000"}
                    text={"Community + Live Coaching"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Element name="checkout">
          <div id="checkout" style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}} ref={elem => this.checkoutRef = elem}>
            <div style={{width: "100%"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8.5vw, 40px)", marginBottom: "2vh", textAlign: "start"}}>
                    {"Get Certified Now"}
                  </div>
{/*                  <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginBottom: "2vh"}}>
                    <VerifiedSvg
                      color={"#000000"}
                      size={"min(4.5vw, 18px)"}
                    />
                    <div onClick={() => this.setState({showSatisfactionGuaranteeModal: true})} style={{display: "inline-block", cursor: "pointer", textDecoration: "underline", textUnderlineOffset: 2, margin: "0px 0.2em", fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", textAlign: "start", fontFamily: "Futura Medium", textTransform: "uppercase"}}>
                      {"30-Day Satisfaction Guarantee"}
                    </div>
                  </div>*/}
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <CertificationStripeCheckoutForm
                    isNative={isNative}
                    deeplinkedCheckoutPlanKey={deeplinkedCheckoutPlanKey}
                    onShowSuccessMessage={() => this.setState({showPaymentResultModal: true, paymentSuccess: true})}
                    onShowErrorMessage={() => this.setState({showPaymentResultModal: true})}
                    deeplinkedAffiliateCode={deeplinkedAffiliateCode}
                    onApplyPromotionCode={promotionCode => {}}
                    discountCodeExpirationMetadata={discountCodeExpirationMetadata}
                  />
                </div>
              </div>
            </div>
          </div>
        </Element>
        <div style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                {"Join 500+ Leading Wellness Professionals"}
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                <span>
                  {"NEUROFIT-Certified Coaches come from many wellness backgrounds. Learn more about their experiences below:"}
                </span>
              </div>
            </div>
          </div>
          <Element name="testimonials" ref={elem => this.testimonialsRef = elem}>
            <div style={{paddingTop: "calc(60px + 3vh)"}}>
              <TrainerTestimonials
                isMobile={isMobile}
                onCategoryClick={() => this.hapticLightOnNative()}
                deeplinkedCategoryKey={deeplinkedTestimonialCategoryKey}
                innerWidth={innerWidth}
              />
            </div>
          </Element>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "flex", justifyContent: "center"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                  onClick={() => {
                    scroller.scrollTo("checkout", {
                      container: this.scrollingContainerRef,
                      duration: 1700,
                      delay: 100,
                      smooth: "easeInOutCubic",
                    });
                  }}
                > 
                  {"JOIN THE CERTIFICATION"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", borderBottom: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                {"Certification Outline"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              {CourseOutlineItems.map(f => (
                <FaqSection
                  key={f.key}
                  question={f.question}
                  answer={f.answer}
                  showAnswer={showCourseDetail[f.key]}
                  onToggleShowAnswer={() => this.setState({showCourseDetail: {...showCourseDetail, [f.key]: (!showCourseDetail[f.key])}})}
                  marginTop={"2vh"}
                />
              ))}
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", paddingTop: "calc(60px + 5vh)", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                {"FAQ'S"}
              </div>
            </div>
          </div>
          <TrainerFAQs />
          <div style={{color: "black", width: "100%", display: "flex", paddingTop: "calc(60px + 5vh)", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                {"GET IN TOUCH"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", paddingTop: "2vh", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 500}}>
              <a
                style={{display: "flex", pointerEvents: "all", textDecoration: "none", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: "#000000", backgroundColor: "#FFFFFF", padding: "18px 0px", border: "none", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}
                href={"mailto:cert@neurofit.app"}
                onClick={() => recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CONTACT_EMAIL_BUTTON)}
                rel={"nofollow noreferrer noopener"}
              >
                <EmailSvg
                  size={"min(6vw, 25px)"}
                  color={"#000000"}
                />
                <span style={{marginInlineStart: "0.4em"}}>
                  {`cert@neurofit.app`}
                </span>
              </a>
              <button
                style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: "#000000", backgroundColor: "#FFFFFF", padding: "18px 0px", border: "none", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}
                onClick={() => recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_CONTACT_INSTAGRAM_BUTTON, NeuroFitInstagramLink)}
              >
                <InstagramSvg
                  size={"min(6vw, 25px)"}
                  color={"#000000"}
                />
                <span style={{marginInlineStart: "0.4em"}}>
                  {`@neurofit.app`}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#000000", borderTop: "2px #000000 solid", borderBottom: "2px #000000 solid"}}>
          <div>
            <div style={{color: "black", width: "100%", display: "flex", color: "#FFFFFF", paddingBottom: "5vh", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{display: "inline-block", marginTop: `calc(60px + 5vh)`, fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"Certification. Tech. Business."}
                </div>
                <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"All-In-One."}
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "95%", maxWidth: 900}}>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "6vh auto"}}>
                  <img fetchpriority="high" src={`/images/certification-assets/bundle-${deviceType}-white.webp`} style={{width: "min(100%, 900px)", height: `calc(min(100%, 900px) * ${bundleDeviceRatio})`}} alt="A 3-Week Nervous System Certification Course for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 700, color: "#FFFFFF"}}>
                <div style={{display: "inline-block", marginTop: `calc(60px + 5vh)`, fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"Any Industry."}
                </div>
                <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", marginInlineEnd: "0.3em", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                  {"Any Clients. "}
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                  <span>{`Our Certification Platform is designed to be `}</span>
                  <span style={{fontFamily: "Futura"}}>{`versatile, simple and practical`}</span>
                  <span>{` —able to plug into`}</span>
                  <span style={{fontFamily: "Futura"}}>{` any wellness business.`}</span>
                </div>
                <div style={{marginTop: "4vh", fontSize: "min(4.5vw, 18px)", color: "#999999", lineHeight: "min(4.5vw, 18px)", textAlign: "center", fontFamily: "Futura", textTransform: "uppercase"}}>
                  {`↓ ${isMobile ? "Tap" : "Click"} Categories To View`}
                </div>
                <div style={{margin: "2vh auto", width: "100%", maxWidth: 700}}>
                  <div>
                    {HealthProfessionalTestimonials.map(obj => {
                      const isSelectedCategory = selectedHealthProfessionalCategory === obj.category_key
                      return (
                        <button
                          style={{display: "inline-block", padding: "0.4vw 1.1vw", fontSize: "min(3.5vw, 18px)", textTransform: "uppercase", fontFamily: "Futura Medium", margin: "10px", backgroundColor: isSelectedCategory ? "#FFFFFF" : "#000000", color: isSelectedCategory ? "#000000" : "#CCCCCC",  border: isSelectedCategory ? "1px solid #FFFFFF" : "1px solid #CCCCCC", transition: "200ms all linear", transitionProperty: "background-color, color, border"}}
                          onClick={() => {
                            this.hapticLightOnNative()
                            recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_INDUSTRY_CATEGORY_BUTTON, {industry_category: obj.category_key})
                            this.setState({selectedHealthProfessionalCategory: obj.category_key})
                          }}
                        >
                          {obj.display_name}
                        </button>
                      )
                    })}
                    <div style={{marginTop: "3vh", color: "#000000"}}>
                      <SwitchTransition mode="out-in">
                        <CSSTransition
                          timeout={400}
                          classNames={"fade-in"}
                          unmountOnExit
                          addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                          }}
                          key={`${selectedHealthProfessionalCategory}`}
                        >
                          <div style={{minHeight: 400}}>
                            {!!selectedHealthProfessionalTestimonial && selectedHealthProfessionalTestimonial.testimonial}
                          </div>
                        </CSSTransition>
                      </SwitchTransition>
                    </div>
                  </div>
                </div>
                {!isNative && (
                  <div style={{marginTop: `calc(60px + 5vh)`}}>
                    <div style={{fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"Trusted By 2,000+ "}
                    </div>
                    <div style={{fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"Wellness Experts. "}
                    </div>
                    <div style={{fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                      {"Loved By Clients."}
                    </div>
                    <div id="scrolling-phone-div" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                      <img src={`/images/hero-phone-balance-desktop.webp`} fetchpriority="low" style={{height: "50vh", zIndex: 1, margin: "3vh 0px"}} />
                      <img src={`/images/hero-phone-insights-desktop.webp`} fetchpriority="low" style={{height: "calc(50vh * 0.896)", zIndex: 0, margin: "calc(3vh + calc(50vh * 0.052)) 0px 3vh 0px"}} />
                    </div>
                    <div style={{textAlign: "center", margin: "3vh auto 1vh auto", opacity: 1.0}}>
                      <StarRatings
                        disabled
                        rating={4.8}
                        starRatedColor={"#FFFFFF"}
                        starEmptyColor={"#444444"}
                        starDimension={"min(8vw, 40px)"}
                        starSpacing={"min(2vw, 10px)"}
                        numberOfStars={5}
                      />
                    </div>
                    <div style={{fontSize: "min(5vw, 26px)", color: "#FFFFFF", textAlign: "center", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                      {"4.8/5 App Store Rating"}
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                      <VerifiedSvg
                        color={"#CFD2DD"}
                        size={"min(4.5vw, 18px)"}
                      />
                      <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#CFD2DD", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                        {"60,000+ Users"}
                      </div>
                    </div>
                    <div style={{marginTop: "6vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{`The `}</span>
                      <span style={{fontFamily: "Futura"}}>{`NEUROFIT App`}</span>
                      <span>{` supports coaches and clients: on average, active users report `}</span>
                      <span style={{fontFamily: "Futura"}}>{`54% less stress after 1 week.`}</span>
                    </div>
{/*                    <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <div>{`Join the Certification and you'll unlock `}</div>
                      <span style={{fontFamily: "Futura"}}>{`Complimentary App Access`}</span>
                      <span>{`:`}</span>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#000000", backgroundColor: "#FFFFFF", padding: "18px 15px", border: "none"}}
                        onClick={() => {
                          scroller.scrollTo("checkout", {
                            container: this.scrollingContainerRef,
                            duration: 1700,
                            delay: 100,
                            smooth: "easeInOutCubic",
                          });
                        }}
                      > 
                        {"GET CERTIFIED NOW"}
                      </button>
                    </div>*/}
                    <DownloadButtonsSection
                      isIOSDevice={isIOS}
                      isAndroidDevice={isAndroid}
                      useWhiteButtons
                      onDownloadButtonClick={() => {
                        if (isMetaInAppBrowser && isAndroid) {
                          this.setState({showMetaInAppDownloadModal: true})
                        }
                      }}
                      onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{paddingBottom: 15 + safeAreaInsets.bottom, paddingTop: "10vh", paddingInlineStart: 15, backgroundColor: "transparent", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", "justifyContent": "left"}}>
              <span style={{color: "#c1c4d2", lineHeight: "min(3.5vw, 16px)"}}>
                {`© NEUROFIT ${currentYear}`}
              </span>
              <span style={{display: "inline-block", fontFamily: "Futura Medium", color: "#c1c4d2", margin: "0px 0.2em", lineHeight: "min(4.5vw, 18px)", fontSize: "min(4.5vw, 18px)"}}>
                {"•"}
              </span>
              <span style={{color: "#c1c4d2", lineHeight: "min(3.5vw, 16px)"}}>
                {"ALL RIGHTS RESERVED"}
              </span>
            </div>
          </div>
        </div>
        <CertificationPaymentResultModal
          show={showPaymentResultModal}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          paymentSuccess={paymentSuccess}
          onClose={() => this.setState({showPaymentResultModal: false})}
        />
        <SatisfactionGuaranteeModal
          show={showSatisfactionGuaranteeModal}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          onClose={() => this.setState({showSatisfactionGuaranteeModal: false})}
        />
        <CertificationVslModal
          show={showCertificationVslModal}
          innerHeight={innerHeight}
          innerWidth={innerWidth}
          isNative={isNative}
          safeAreaInsets={safeAreaInsets}
          onClose={() => this.setState({showCertificationVslModal: false})}
        />
        <QrCodeDownloadModal
          show={showQrCodeModal}
          onHide={() => this.setState({showQrCodeModal: false})}
          safeAreaInsets={safeAreaInsets}
        />
        <MetaInAppDownloadModal
          show={showMetaInAppDownloadModal}
          onHide={() => this.setState({showMetaInAppDownloadModal: false})}
          safeAreaInsets={safeAreaInsets}
        />
      </div>
    )
  }
}

export default TrainerCertificationDetails