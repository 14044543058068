import React, {Suspense} from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import DownloadButtonsSection from './common/DownloadButtonsSection'
import AutoPlayInlineVideoContainer from './common/AutoPlayInlineVideoContainer'
import PieChartInsightSection from './common/PieChartInsightSection'
import LineGraph from './common/LineGraph'
import NeuroFitProgressRing from './common/NeuroFitProgressRing'
import MembershipFeatureDescription from './common/MembershipFeatureDescription'
import AppReviewPanel from './common/AppReviewPanel'
import UniversitySourcesSection from './common/UniversitySourcesSection'
import FaqSection from './common/FaqSection'
import LandingPageLinksModal from './modals/LandingPageLinksModal'
import QrCodeDownloadModal from './modals/QrCodeDownloadModal'


import { history } from '../history'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  NeuroFitInstagramLink,
  NeuroFitLinkedInLink,
  ProductionBaseUrl,
  ProductionWorkshopsUrl,
  ProductionCertificationUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordLinkClickGA,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  initializePixelIfAvailable,
  NeuroFitSkoolCommunityLink,
  isProduction,
  onceEventListener,
  BlogSomaticExercisesWeightLossUrl,
  BlogSomaticExercisesSleepUrl,
  BlogSomaticExercisesTraumaUrl,
  BlogDorsalVagalRegulationUrl,
  BlogNervousSystemRegulationTimeUrl,
  BlogSomaticExercisesAngerUrl,
  BlogNervousSystemCoachUrl,
  BlogSomaticCoachUrl,
  LearnOverstimulatedUrl,
  LearnSomaticYogaUrl,
  LearnFunctionalFreezeUrl,
  LearnCoreSleepUrl,
  LearnBrainMuscleUrl,
  ProductionLegalTermsOfServiceUrl,
  ProductionLegalPrivacyPolicyUrl,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
} from '../utils'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
  InstagramSvg,
  LinkedInSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_WORKSHOP_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_BUTTON,
  GA_LABEL_CLICKED_TEAMS_BUTTON,
  GA_LABEL_CLICKED_BLOG_BUTTON,
  GA_LABEL_CLICKED_LEARN_BUTTON,
  GA_LABEL_CLICKED_ABOUT_BUTTON,
  GA_LABEL_CLICKED_COURSE_BUTTON,
  GA_LABEL_CLICKED_N_PLUS_BUTTON,
  GA_LABEL_CLICKED_QUIZ_BUTTON,
} from '../constants/gaEventLabels'

// import {

// } from '../constants/experimentFlags'

import StarRatings from 'react-star-ratings';

import loadable from '@loadable/component'

const MobileAppTestimonials = loadable(() => import('./common/MobileAppTestimonials'))

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const FooterSiteLink = ({url, title}) => (
  <a
    href={url}
    target="_blank"
    style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
  >
    {title}
  </a>
)

const getArticleUrl = key => `https://neurofit.app/learn/en/${key}/`

const learnArticleSiteLinks = [
  {
    url: getArticleUrl("what-are-somatic-exercises"),
    title: "What Are Somatic Exercises?"
  },
  {
    url: getArticleUrl("what-are-somatic-workouts"),
    title: "What Are Somatic Workouts?"
  },
  {
    url: getArticleUrl("what-is-a-dysregulated-nervous-system"),
    title: "What Is A Dysregulated Nervous System?"
  },
  {
    url: getArticleUrl("window-of-tolerance"),
    title: "The Window of Tolerance"
  },
  {
    url: getArticleUrl("top-9-dysregulated-nervous-system-symptoms"),
    title: "Top Dysregulated Nervous System Symptoms"
  },
  {
    url: getArticleUrl("how-to-regulate-a-dysregulated-nervous-system"),
    title: "How To Regulate Your Nervous System"
  },
  {
    url: getArticleUrl("5-somatic-exercises-for-anxiety"),
    title: "Somatic Exercises For Anxiety"
  },
  {
    url: getArticleUrl("5-somatic-exercises-for-anger"),
    title: "Somatic Exercises For Anger"
  },
  {
    url: getArticleUrl("5-somatic-exercises-to-relieve-stress-in-minutes"),
    title: "Somatic Exercises To Relieve Stress"
  },
  {
    url: getArticleUrl("what-is-cortisol-face"),
    title: "What Is Cortisol Face?"
  },
  {
    url: LearnSomaticYogaUrl,
    title: "What Is Somatic Yoga?"
  },
  {
    url: LearnFunctionalFreezeUrl,
    title: "Functional Freeze / Shutdown"
  },
  {
    url: LearnCoreSleepUrl,
    title: "What is Core Sleep?"
  },
  {
    url: LearnOverstimulatedUrl,
    title: "Overstimulated? Here's What To Do"
  },
  {
    url: LearnBrainMuscleUrl,
    title: "Is the Brain a Muscle?"
  },
]

const LandingPageFAQItems = [
  {
    key: "background_science_header",
    is_header: true,
    text: "Logistics & Science"
  },
  {
    key: "daily_commitment",
    question: "What is the daily time commitment for success?",
    answer: "Most members spend about 5 minutes a day inside the app. Beyond this, the recommended embodiment routine takes about 30 minutes each day (10 minutes each of social play, exercise, and stillness)."
  },
  {
    key: "science",
    question: "What is the data + science behind NEUROFIT?",
    answer: (
      <span>
        <span>{"NEUROFIT is data-driven and grounded in neuroscience. Our nervous system approach is validated by 7M+ in-app datapoints and supported by leading neuroscience research."}</span>
      </span>
    )
  },
  {
    key: "hrv_methodology",
    question: "How does your HRV measurement tech work?",
    answer: "To measure HRV, NEUROFIT reads changes in finger color via your phone's camera, to detect naturally occurring changes in heartbeat rhythm. This clinically validated technique is known as \"photoplethysmography\", and achieves a real-world accuracy of 96% compared to gold-standard (ECG-comparable) sensors in the appropriate conditions (eg, lighting, exposure, and finger pressure)."
  },
  {
    key: "app_design_header",
    is_header: true,
    text: "App Design"
  },
  {
    key: "simple_app",
    question: "The app's design seems simple and minimalist. Why is this?",
    answer: "This is intentional: the nervous system is balanced through action, not accumulating knowledge. Two common roadblocks when resolving nervous system dysegulation are overthinking, and the dopamine release that comes from consuming excessive information. The app experience is designed to prevent both of these, increasing the likelihood that our members take action."
  },
  {
    key: "balance_score",
    question: "How should I interpret my daily BALANCE score?",
    answer: "The NEUROFIT Balance Score is a percentage measuring the nervous system's overall flexibility and readiness to be challenged. It's a short-term indicator of nervous system strength, resilience and recovery on a given day, and is measured relative to other nervous system readings you've taken lately."
  },
  {
    key: "membership_header",
    is_header: true,
    text: "Membership"
  },
  {
    key: "membership_logistics",
    question: "What are the logistics of membership?",
    answer: "After a 3-day free trial, membership can be accessed with either a 3 month or yearly subscription. Membership is auto-renewing and can be managed through the App Store or Play Store at any time."
  },
  {
    key: "membership_price_duration",
    question: "What is the investment for first-time membership?",
    answer: "For 3-day free trials started on day one, membership is priced at either $39.99 USD per 3 months, or $124.99 USD per year."
  },
  {
    key: "membership_time",
    question: "Why the 3 month membership plan?",
    answer: "Creating long-lasting changes in the nervous system takes about 6 weeks (the length of our program). To optimize results, we want you to commit to these changes and to be able to complete the program twice in one membership cycle. Our data has shown that this price point self-selects for members who are prepared to take ownership of their well-being and create positive outcomes."
  }
]

let defaultShowAnswer = {}

LandingPageFAQItems.map(key => defaultShowAnswer[key] = false)

class LandingPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showAnswer: defaultShowAnswer,
      showSettingsModal: false,
      showQrCodeModal: false,
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      pageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isVideoLoaded: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      hasScrolled: false
    }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const pageScrollProgress = Math.max((e.target.scrollTop / innerHeight), 0.0)
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true
      this.setState({headerBackgroundColor, pageScrollProgress, heroOverlayColor, dropShadowColor, hasScrolled, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity})
    } else {
      this.setState({headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, pageScrollProgress: 0, hasScrolled: true, headerBorderColor: "transparent", videoOpacity: 1})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isIOSDevice,
      isAndroidDevice,
      experimentFlags
    } = this.props

    const {show, showAnswer, learnMoreRef, showSettingsModal, showQrCodeModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, isHeroImageLoaded, logoOutlineColor, dropShadowColor, topPageScrollProgress, pageScrollProgress, showIframe, isVideoLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, hasScrolled} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const phoneAspectRatio = 1841 / 907

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    const currentYear = getCurrentYear()

    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <LandingPageLinksModal
            show={showSettingsModal}
            onHide={() => this.setState({showSettingsModal: false})}
            appDownloadUrl={appDownloadUrl}
            onClickDownloadAppButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK)
            }}
            onClickTrainerCertificationButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_CERTIFICATION_BUTTON)
            }}
            onClickTeamsButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_TEAMS_BUTTON)
            }}
            onClickBlogButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_BLOG_BUTTON)
            }}
            onClickLearnButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_LEARN_BUTTON)
            }}
            onClickAboutButton={() => {
              recordLinkClickGA(GA_LABEL_CLICKED_ABOUT_BUTTON)
            }}
            safeAreaInsets={safeAreaInsets}
          />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
              onClick={() => window.location = "/"}
            />
            {!isMobile ? (
              <div style={{display: "flex", alignItems: "center"}}>
                <span
                  onClick={() => {
                    if (isIOSDevice || isAndroidDevice) {
                      recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                    } else {
                      this.setState({showQrCodeModal: true})
                    }
                  }}
                  style={{cursor: "pointer", fontFamily: "Futura Medium", textShadow, fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                >
                  {"App"}
                </span>
                <span style={{display: "inline-block", color: "#000000", fontFamily: "Futura Medium", margin: "0px 0.2em", textShadow, fontSize: "min(4vw, 18px)"}}>
                  {"•"}
                </span>
                <a
                  href="/certification"
                  onClick={() => recordLinkClickGA(GA_LABEL_CLICKED_CERTIFICATION_BUTTON)}
                  style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textDecoration: "none", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                >
                  {"Certification"}
                </a>
                <span style={{display: "inline-block", color: "#000000", fontFamily: "Futura Medium", margin: "0px 0.2em", textShadow, fontSize: "min(4vw, 18px)"}}>
                  {"•"}
                </span>
                <a
                  href="/teams"
                  target="_blank"
                  style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", textDecoration: "none", color: "#000000", cursor: "pointer"}}
                >
                  {"Work"}
                </a>
                <span style={{display: "inline-block", color: "#000000", fontFamily: "Futura Medium", margin: "0px 0.2em", textShadow, fontSize: "min(4vw, 18px)"}}>
                  {"•"}
                </span>
                <a
                  href="/blog"
                  target="_blank"
                  style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", textDecoration: "none", color: "#000000", cursor: "pointer"}}
                >
                  {"Blog"}
                </a>
                <span style={{display: "inline-block", color: "#000000", fontFamily: "Futura Medium", margin: "0px 0.2em", textShadow, fontSize: "min(4vw, 18px)"}}>
                  {"•"}
                </span>
                <a
                  href="/nervous-system-glossary/en"
                  target="_blank"
                  style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", textDecoration: "none", color: "#000000", cursor: "pointer"}}
                >
                  {"Learn"}
                </a>
                <span style={{display: "inline-block", color: "#000000", fontFamily: "Futura Medium", margin: "0px 0.2em", textShadow, fontSize: "min(4vw, 18px)"}}>
                  {"•"}
                </span>
                <a
                  href="/about"
                  target="_blank"
                  style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", textDecoration: "none", color: "#000000", cursor: "pointer"}}
                >
                  {"About"}
                </a>
              </div>
            ) : (
              <button
                onClick={() => {
                  this.setState({showSettingsModal: true})
                }}
                style={{padding: 0, margin: "0px 7px", backgroundColor: "transparent", border: "none"}}
              >
                <HamburgerSvg size="min(5vw, 22px)" dropShadow={svgDropShadow} />
              </button>
            )}
          </div>
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: showSettingsModal ? "rgba(0, 0, 0, 0.5)" : "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
            onClick={() => {
              this.manualStartRef.playVideo()
            }}
          >
            <div style={{textAlign: "start", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
              <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: -2}}>
                <div style={{width: videoWidthPixels, opacity: videoOpacity}}>
                  <AutoPlayInlineVideoContainer
                    videoPath={isMobile ? "/assets/videos/landing-page-exercises.mp4" : "/assets/videos/landing-page-exercises-720p.mp4"}
                    baseContentUrl={ProductionBaseUrl}
                    widthPixels={videoWidthPixels}
                    isVideoVisible
                    playbackRate={0.85}
                    registerManualStartEvent={triggerManualVideoStart => {
                      // Ensure Hero Video becomes controllable on iOS in Low Power Mode.
                      onceEventListener(window, "touchstart", function (e) {
                        triggerManualVideoStart()
                      });
                    }}
                    ref={elem => this.manualStartRef = elem}
                    onVideoLoad={() => {
                      this.setState({videoDownloadProgress: 1})
                      initializePixelIfAvailable()
                    }}
                  />
                </div>
              </div>
              <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
                <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                    <div style={{width: "88%", maxWidth: 700}}>
                      <div style={{fontFamily: "Futura", textShadow, fontSize: "min(8.5vw, 40px)", textTransform: "uppercase", marginTop: "3vh", textAlign: "start"}}>
                        <div>{"Beat Stress Fast W/"}</div>
                        <div style={{fontFamily: "Futura Medium"}}>
                          {"Nervous System Fitness™"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "2vh", pointerEvents: "none"}}>
                    <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                      <DownloadButtonsSection
                        isIOSDevice={isIOSDevice}
                        isAndroidDevice={isAndroidDevice}
                        useWhiteButtons
                        onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                        onDownloadButtonClick={() => this.setState({hasScrolled: true})}
                      />
                      <div style={{display: "flex", justifyContent: "center", marginTop: "1vh", pointerEvents: "none"}}>
                        <svg onClick={() => this.learnMoreRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#FFFFFF"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}} onClick={() => this.manualStartRef.playVideo()}>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}} ref={elem => this.learnMoreRef = elem}>
              <div style={{height: "100%"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                      {"Backed By Data."}
                    </div>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"Trusted By 2,000+ Health Experts."}
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                      <img src={`/images/hero-phone-balance-${isMobile ? "mobile" : "desktop"}.webp`} fetchpriority="high" style={{height: "50vh", zIndex: 1, margin: "3vh 0px", transform: `translateX(calc(-1 * ${pageScrollProgress - 1.3}  * 5vh))`}} />
                      <img src={`/images/hero-phone-insights-${isMobile ? "mobile" : "desktop"}.webp`} fetchpriority="high" style={{height: "calc(50vh * 0.896)", zIndex: 0, margin: "calc(3vh + calc(50vh * 0.052)) 0px 3vh 0px", transform: `translateX(calc(${pageScrollProgress - 1.3} * 5vh))`}} />
                    </div>
                    <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{`NEUROFIT is designed to improve well-being fast: on average, active users report `}</span>
                      <span style={{fontFamily: "Futura"}}>{`54% less stress after 1 week.`}</span>
                    </div>
                    <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span style={{fontFamily: "Futura"}}>{`Hundreds of therapists, doctors, health coaches, and more`}</span>
                      <span>{` use our nervous system method with clients.`}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "5vh", pointerEvents: "none"}}>
                      <img src={isMobile ? "/images/media-logos-4-mobile.webp?v=3" : "/images/media-logos-4-desktop.webp?v=3"} fetchpriority="high" style={{width: "85%", maxWidth: 500, height: "calc(min(500px, 85%) / 8.577)", opacity: 0.5}} alt="NEUROFIT has been featured in SHAPE, Well and Good, and Prevention." />
                    </div>
                    <div style={{textAlign: "center", margin: "5vh auto 2vh auto", opacity: 1.0}}>
                      <div>
                        <StarRatings
                          disabled
                          rating={4.8}
                          starRatedColor={"#000000"}
                          starEmptyColor={"#CFD2DD"}
                          starDimension={"min(8vw, 40px)"}
                          starSpacing={"min(2vw, 10px)"}
                          numberOfStars={5}
                        />
                      </div>
                      <div style={{fontSize: "min(5vw, 26px)", textAlign: "center", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                        {"4.8/5 App Store Rating"}
                      </div>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                        <VerifiedSvg
                          color={"#a5a8b0"}
                          size={"min(4.5vw, 18px)"}
                        />
                        <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                          {"60,000+ Users"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{color: "black", width: "100%",display: "flex", justifyContent: "center", alignItems: "top"}}>
                    <div style={{width: "85%", maxWidth: 700}}>
                      <DownloadButtonsSection
                        isIOSDevice={isIOSDevice}
                        isAndroidDevice={isAndroidDevice}
                        onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", minHeight: `calc(${innerHeight}px - 120px - 10vh)`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Your Mind-Body Mental Health Coach"}
                  </div>
                  <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <div style={{marginTop: "6vh"}}>
                      {"Chronic stress imbalances the nervous system. Mindset falls short here: only body-based methods can overcome this."}
                    </div>
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                      <div style={{width: "100%", maxWidth: 450}}>
                        <PieChartInsightSection
                          title={`EMBODIMENT VS. MINDSET`}
                          marginTop={"max(3vw, 16px)"}
                          disableDropDown
                          dataIsAvailable
                          hideCircle
                          data={{"embodiment": 80, "mindset": 20}}
                          insightText={`NEUROFIT's embodiment approach speaks to 80% of the nervous system. Mindset only speaks to the other 20%.`}
                          dataCategories={[{title_name: "Embodiment", name: "embodiment", color: "#FFD634"}, {title_name: "Mindset", name: "mindset", color: "#9EA0DA"}]}
                        />
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{"The good news? Nervous System Fitness improves holistic well-being fast:"}</span>
                    </div>
                    <div style={{display: "flex", marginTop: "2vh", width: "100%", justifyContent: "center"}}>
                      <div style={{backgroundColor: "#FFFFFF", width: "100%", maxWidth: 450, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                        <div style={{padding: "min(24px, max(3vw, 16px))"}}>
                          <div style={{fontSize: "min(5vw, 22px)", textAlign: "start", fontFamily: "Futura Medium"}}>
                            {"NERVOUS SYSTEM FITNESS:"}
                          </div>
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Quickly Reduces Stress + Anxiety"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Enhances Focus + Creativity"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Improves Emotional + Mental Clarity"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Optimizes Sleep + Accelerates Recovery"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Boosts Immunity + Digestion"}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{"Within just 5 minutes of NEUROFIT exercises, 95% of members report relief from real-time stress:"}</span>
                    </div>
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                      <div style={{width: "100%", maxWidth: 450}}>
                        <LineGraph
                          dataIsAvailable
                          title={`REAL-TIME STRESS REDUCTION`}
                          data={{
                            labels: ["0", "1", "2", "3"],
                            series: [[100, 11.2, 5, 1.4]],
                          }}
                          labelInterpolationFnc={(val) => `${val}%`}
                          highYValue={100}
                          hideLogo
                          xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                        />
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", marginBottom: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{`The NEUROFIT Method is validated by.`}</span>
                      <span style={{fontFamily: "Futura"}}>{`7M+ in-app datapoints`}</span>
                      <span>{`, and grounded in leading neuroscience research.`}</span>
                    </div>
                    <DownloadButtonsSection
                      isIOSDevice={isIOSDevice}
                      isAndroidDevice={isAndroidDevice}
                      onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                <img src="/images/shape-groundbreaking-recovery-innovations-white.webp?v=3" style={{width: "calc(75% * 0.908)", maxWidth: 400 * 0.908}} alt="NEUROFIT is a 2023 SHAPE Magazine Groundbreaking Recovery Innovation." />
              </div>
              <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
                <img src="/images/well-and-good-laurel-white.webp?v=2" style={{width: "75%", maxWidth: 400, height: "calc(min(75%, 400px) * 120 / 501)"}} alt="NEUROFIT eased my stress in ways meditation never has -Well and Good" />
              </div>
              <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
                <img src="/images/prevention-laurel-white.webp?v=2" style={{width: "75%", maxWidth: 400, height: "calc(min(75%, 400px) * 132 / 501)"}} alt="Balance your nervous system in 3 minutes or less. -Prevention" />
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Vetted By Leading Health Professionals"}
                  </div>
                </div>
              </div>
              <MobileAppTestimonials />
            </div>
            <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start", whitepace: "pre-wrap"}}>
                    <div>{isMobile ? "Become a Certified Nervous System + Somatics Coach" : "Become A Certified Somatics + Nervous System Coach"}</div>
                  </div>
                  <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                    {hasScrolled && (
                      <img src={isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH} loading="lazy" style={{width: "100%", maxWidth: 600}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                    )}
                  </div>
                  <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                    <span>{`Master nervous system+somatics and attract new clients 10x faster with our`}</span>
                    <span style={{fontFamily: "Futura"}}>{` all-in-one certification + tech platform`}</span>
                    <span>{`.`}</span>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <a
                      href="/certification"
                      style={{display: "flex", pointerEvents: "all", textDecoration: "none", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        recordLinkClickGA(GA_LABEL_CLICKED_CERTIFICATION_BUTTON)
                      }}
                    > 
                      {"VIEW CERTIFICATION"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
              <div style={{fontFamily: "Futura Medium", color: "#FFFFFF", textTransform: "uppercase", fontSize: "min(6vw, 26px)", textAlign: "center"}}>
                {"Approved Provider Of"}
              </div>
              <div style={{display: "flex", width: "100%", marginTop: "2vh", alignItems: "center", justifyContent: "space-around"}}>
                <img fetchpriority="high" src="/images/nasm-afaa-black-and-white.webp?v=2" style={{width: "min(80%, 500px)", height: "calc(min(80%, 500px) * 252 / 897)"}} alt="NEUROFIT is a NASM + AFAA Approved Provider." />
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{fontFamily: "Futura", color: "#000000", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start"}}>
                    <div>{"Solve Team Burnout In Just 6 Weeks"}</div>
                  </div>
                  <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                    <span>{`Equip your team with NEUROFIT's `}</span>
                    <span style={{fontFamily: "Futura"}}>{`high-performance burnout tech`}</span>
                    <span>{` used by military nurses, physicians, therapists and more.`}</span>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <a
                      href="/teams"
                      style={{display: "flex", textDecoration: "none", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        recordLinkClickGA(GA_LABEL_CLICKED_TEAMS_BUTTON)
                      }}
                    > 
                      {"VIEW TEAM PACKAGES"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", backgroundColor: "#FFFFFF", justifyContent: "center", alignItems: "top", borderTop: "2px #000000 solid", padding: "calc(60px + 5vh) 0px"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"GET IN TOUCH"}
                </div>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                  <div style={{marginTop: "min(6vh, 30px)", marginBottom: "min(6vh, 30px)", backgroundColor: "#EDEEF2", width: "100%", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)"}}>
                    <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                      {`PRESS & MEDIA`}
                    </div>
                    <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "start", fontFamily: "Futura"}}>
                      <a href="mailto:press@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                        {"press@neurofit.app"}
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                  <div style={{backgroundColor: "#EDEEF2", marginBottom: "min(6vh, 30px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                    <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                      {`BUSINESS INQUIRIES`}
                    </div>
                    <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "start", fontFamily: "Futura"}}>
                      <a href="mailto:contact@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                        {"contact@neurofit.app"}
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "min(6vh, 30px)", width: "100%"}}>
                  <div style={{backgroundColor: "#EDEEF2", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                    <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                      {`APP CUSTOMER CARE`}
                    </div>
                    <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "start", fontFamily: "Futura"}}>
                      <a href="mailto:care@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                        {"care@neurofit.app"}
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                  <div style={{backgroundColor: "#EDEEF2", marginBottom: "min(6vh, 30px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                    <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                      {`CERTIFICATION HELP`}
                    </div>
                    <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "start", fontFamily: "Futura"}}>
                      <a href="mailto:cert@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                        {"cert@neurofit.app"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", paddingTop: `calc(60px + 5vh)`, borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingBottom: "calc(60px + 5vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"APP FAQ"}
                  </div>
                  {LandingPageFAQItems.map((f) => (
                    (f.is_header ? (
                      <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "start", fontFamily: "Futura"}}>
                        {f.text}
                      </div>
                    ) : (
                      <FaqSection
                        key={f.key}
                        question={f.question}
                        answer={f.answer}
                        showAnswer={showAnswer[f.key]}
                        onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                        marginTop={"2vh"}
                      />
                    ))
                  ))}
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", backgroundColor: "#000000", paddingTop: "calc(60px + 5vh)", borderTop: "2px #000000 solid"}}>
              <div>
                <div style={{color: "black", width: "100%", display: "flex", color: "#FFFFFF", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{width: "100%", display: "flex", color: "#FFFFFF", justifyContent: "left", alignItems: "top"}}>
                      <NeuroFitNamedLogoSvg
                        logoSize={"min(5vw, 20px)"}
                        color={"#FFFFFF"}
                      />
                      <img src={"/images/neurofit-app-icon-1x.webp"} style={{marginInlineStart: 30, height: 100, width: 100, filter: `drop-shadow(0px 0px 3px #A2A5B0)`}} />
                    </div>
                    <div style={{fontFamily: "Futura Book", display: "block", margin: "3vh 0px", fontSize: "min(4.5vw, 18px)", color: "#CFD2DD"}}>
                      {"Balance your nervous system, reduce stress, and feel your best with NEUROFIT."}
                    </div>
                    <div style={{color: "black", width: "100%", display: "flex", justifyContent: "left", alignItems: "center"}}>
                      <a
                        itemprop="sameAs"
                        href={NeuroFitInstagramLink}
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{backgroundColor: "#1F1F1F", cursor: "pointer", borderRadius: "5px", height: "min(9vw, 45px)", width: "min(9vw, 45px)", display: "flex", justifyContent: "center", alignItems: "center"}}
                      >
                        <InstagramSvg
                          color={"#CFD2DD"}
                          size={"min(5vw, 22px)"}
                        />
                      </a>
                      <a
                        itemprop="sameAs"
                        href={NeuroFitLinkedInLink}
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{marginInlineStart: "min(6vw, 26px)", backgroundColor: "#1F1F1F", cursor: "pointer", borderRadius: "5px", height: "min(9vw, 45px)", width: "min(9vw, 45px)", display: "flex", justifyContent: "center", alignItems: "center"}}
                      >
                        <LinkedInSvg
                          color={"#CFD2DD"}
                          size={"min(5vw, 22px)"}
                        />
                      </a>
                    </div>
                    <DownloadButtonsSection
                      isIOSDevice={isIOSDevice}
                      isAndroidDevice={isAndroidDevice}
                      whiteButtonBorder
                      onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                      onDownloadButtonClick={() => this.setState({hasScrolled: true})}
                    />
                    <div style={{fontFamily: "Futura", marginTop: "5vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                      {"PRODUCTS"}
                    </div>
                    <a
                      href={"/certification"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Nervous System+Somatics Certification"}
                    </a>
                    <span
                      onClick={() => {
                        if (isIOSDevice || isAndroidDevice) {
                          recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                        } else {
                          this.setState({showQrCodeModal: true})
                        }
                      }}
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"NEUROFIT Mobile App"}
                    </span>
                    <a
                      href={"/teams"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"NEUROFIT For Teams/Organizations"}
                    </a>
                    <div style={{fontFamily: "Futura", marginTop: "6vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                      {"TOOLS FOR PRACTITIONERS"}
                    </div>
                    <a
                      href={"/balance"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Personalized Somatic Exercise Tool"}
                    </a>
                    <a
                      href={"/quiz"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Nervous System Dysregulation Quiz"}
                    </a>
                    <a
                      href={"/nervous-system-glossary/en"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Nervous System + Somatics Glossary"}
                    </a>
                    <div style={{fontFamily: "Futura", fontSize: "min(5.5vw, 22px)", marginTop: "6vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                      {"TOP BLOG POSTS"}
                    </div>
                    <a
                      href={BlogSomaticExercisesWeightLossUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Somatic Exercises For Weight Loss"}
                    </a>
                    <a
                      href={BlogSomaticExercisesSleepUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Somatic Exercises For Better Sleep"}
                    </a>
                    <a
                      href={BlogSomaticExercisesTraumaUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Somatic Exercises To Relieve Trauma"}
                    </a>
                    <a
                      href={BlogSomaticExercisesAngerUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Somatic Exercises For Anger"}
                    </a>
                    <a
                      href={BlogDorsalVagalRegulationUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"How to Resolve Dorsal Vagal Shutdown"}
                    </a>
                    <a
                      href={BlogNervousSystemRegulationTimeUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"How Long To Regulate the Nervous System?"}
                    </a>
                    <a
                      href={BlogNervousSystemCoachUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Become A Nervous System Coach"}
                    </a>
                    <a
                      href={BlogSomaticCoachUrl}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Become A Somatic Coach"}
                    </a>
                    <div style={{fontFamily: "Futura", fontSize: "min(5.5vw, 22px)", marginTop: "6vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                      {"TOP LEARN ARTICLES"}
                    </div>
                    {learnArticleSiteLinks.map(({url, title}) => (
                      <FooterSiteLink
                        id={url}
                        url={url}
                        title={title}
                      />
                    ))}
                    <div style={{fontFamily: "Futura", marginTop: "5vh", fontSize: "min(5.5vw, 22px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}>
                      {"BUSINESS"}
                    </div>
                    <a
                      href={"/about"}
                      target="_blank"
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"About Us"}
                    </a>
                    <span
                      onClick={() => {
                        window.open(ProductionLegalTermsOfServiceUrl, "_blank", "noopener nofollow")
                      }}
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Terms of Service"}
                    </span>
                    <span
                      onClick={() => {
                        window.open(ProductionLegalPrivacyPolicyUrl, "_blank", "noopener nofollow")
                      }}
                      style={{fontFamily: "Futura Book", display: "block", margin: "1vh 0px", fontSize: "min(4.5vw, 18px)", textDecoration: "underline", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", cursor: "pointer"}}
                    >
                      {"Privacy Policy"}
                    </span>
                    <div style={{marginTop: "2vh", color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`Xama Technologies, Inc. (dba NEUROFIT)`}
                    </div>
                    <div style={{color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`651 North Broad Street, Suite 206`}
                    </div>
                    <div style={{color: "#FFFFFF", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`Middletown, DE, USA 19709`}
                    </div>
                    <a href="mailto:care@neurofit.app" style={{marginTop: "2vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`care@neurofit.app`}
                    </a>
                    <a href="mailto:press@neurofit.app" style={{marginTop: "1vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`press@neurofit.app`}
                    </a>
                    <a href="mailto:contact@neurofit.app" style={{marginTop: "1vh", textUnderlineOffset: "3.5px", textDecorationThickness: "0.03em", color: "#FFFFFF", display: "block", fontFamily: "Futura Book", fontSize: "min(3.5vw, 16px)"}}>
                      {`contact@neurofit.app`}
                    </a>
                    <div style={{paddingBottom: 15 + safeAreaInsets.bottom, paddingTop: "10vh", backgroundColor: "transparent", fontSize: "min(4vw, 18px)", fontFamily: "Futura", display: "flex", "justifyContent": "left"}}>
                      <span style={{color: "#FFFFFF", lineHeight: "min(3.5vw, 16px)"}}>
                        {`© NEUROFIT ${currentYear}`}
                      </span>
                      <span style={{display: "inline-block", fontFamily: "Futura Medium", color: "#FFFFFF", margin: "0px 0.2em", lineHeight: "min(5.5vw, 22px)", fontSize: "min(4.5vw, 18px)"}}>
                        {"•"}
                      </span>
                      <span style={{color: "#FFFFFF", lineHeight: "min(4vw, 18px)"}}>
                        {"ALL RIGHTS RESERVED"}
                      </span>
                    </div>
                    <div style={{fontFamily: "Futura Book", display: "block", marginTop: "4vh", fontStyle: "italic", fontSize: 15, color: "#a5a8b0"}}>
                      {"The NEUROFIT products, programs and services are intended to help maintain and encourage a healthy lifestyle, and are not to be used for the diagnosis, cure, management, prevention, or treatment of any disease or condition."}
                    </div>
                    <div style={{fontFamily: "Futura Book", display: "block", fontStyle: "italic", margin: "1vh 0px 4vh 0px", fontSize: 15, color: "#a5a8b0"}}>
                      {"As always, speak with your trusted health professional before making any health-related decisions or using any health-related products or services."}
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QrCodeDownloadModal
          show={showQrCodeModal}
          onHide={() => this.setState({showQrCodeModal: false})}
          safeAreaInsets={safeAreaInsets}
        />
      </div>
    )
  }
}

export default LandingPage;